<template lang="pug">
  ElTable(
    :data="holdersList"
    :default-sort="sort"
    ref="holderTable"
    row-key="position"
    border
    @row-click="onRowClick"
  )

    ElTableColumn(
      prop="position"
      label="№"
      width="48"
      show-overflow-tooltip
      sortable
    )

    ElTableColumn(
      prop="name"
      label="Name"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="quantity"
      label="Quantity"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="percentage"
      label="Percentage"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="amount"
      label="Amount"
      class-name="cursor-pointer"
    )
</template>

<script>
export default {
  name: 'TopHoldersTable',
  props: {
    holdersList: {
      type: Array,
      required: true,
    },
    sort: {
      type: Object,
      default: () => ({ prop: 'position', order: 'ascending' }),
    },
  },
  methods: {
    onRowClick(row) {
      window.open(row.url, '_blank');
    },
  },
};
</script>

<style scoped>
.icn, .expand-title {
  font-size: 1.25rem;
}
.icn.active {
  color: #67C23A;
}
.expand-title, .condition, .condition-title {
  padding: 0;
}
.expand-title {
  margin: 0 0 1rem 0;
}
.condition + .condition {
  margin-top: 1rem;
}
.condition-title {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}
.condition-item {
  padding: 0 0 0 1%;
  margin: 0;
}
</style>
