import axios from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL || 'https://wwymonitor.com/api',
  withCredentials: true,
});

/* Так себе солюшен, да */
export const setApiGlobalErrorHandler = (fn) => {
  apiClient.interceptors.response.use(
    response => response,
    (error) => {
      // Do something with response error
      fn(error);
      throw error;
    },
  );
};
