import {
  getUserData, login, logout, initialAdminCreate,
} from '@/api';

export default {
  namespaced: true,
  state: {
    userData: null,
  },
  getters: {
    isAdmin(state) {
      return state.userData && state.userData.role === 'admin';
    },
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
  },
  actions: {
    async getUserData({ commit }) {
      const userData = await getUserData();
      commit('setUserData', userData);
    },
    async login({ commit }, { name, pass }) {
      const userData = await login({ name, pass });
      commit('setUserData', userData);
    },
    async logout() {
      await logout();
      window.location.reload();
    },
    async createAdmin(_, pass) {
      await initialAdminCreate(pass);
    },
  },
};
