<template lang="pug">
  ElTable(
    :data="holdersList"
    :default-sort="sort"
    ref="holderTable"
    row-key="id"
    border
    @row-click="onRowClick"
  )

    ElTableColumn(
      prop="id"
      label="ID"
      width="48"
      show-overflow-tooltip
      sortable
    )

    ElTableColumn(
      prop="name"
      label="Name"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="address"
      label="address"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="amount"
      label="Last outgoing tx"
      width="196"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      prop="balanceWWY"
      label="Current balance"
      width="144"
      class-name="cursor-pointer"
    )

    ElTableColumn(
      label="Actions"
      align="center"
      width="100"
    )
      template(slot-scope="{row}")
        ElButton(
          title="Edit"
          type="primary"
          size="mini"
          icon="el-icon-edit"
          circle
          @click="onEditHolder(row)"
        )
        ElButton(
          title="Delete"
          type="danger"
          size="mini"
          icon="el-icon-delete"
          circle
          @click="onDeleteHolder(row)"
        )
</template>

<script>
export default {
  name: 'HoldersTable',
  props: {
    holdersList: {
      type: Array,
      required: true,
    },
    sort: {
      type: Object,
      default: () => ({ prop: 'id', order: 'descending' }),
    },
  },
  methods: {
    // dateFormatter(holder, column, dateISO) {
    //   return new Date(dateISO).toLocaleString();
    // },
    onRowClick(row, { property }) {
      if (property === 'name' || property === 'address') {
        this.$refs.holdersTable.toggleRowExpansion(row);
      }
    },
    onEditHolder(holder) {
      this.$emit('edit', holder);
    },
    onDeleteHolder(holder) {
      this.$emit('delete', holder);
    },
    onToggleActiveHolder(holder) {
      this.$emit('toggleActive', holder);
    },
  },
};
</script>

<style scoped>
.icn, .expand-title {
  font-size: 1.25rem;
}
.icn.active {
  color: #67C23A;
}
.expand-title, .condition, .condition-title {
  padding: 0;
}
.expand-title {
  margin: 0 0 1rem 0;
}
.condition + .condition {
  margin-top: 1rem;
}
.condition-title {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}
.condition-item {
  padding: 0 0 0 1%;
  margin: 0;
}
</style>
