import { getProvidersWithSubProviders } from '@/api/providers';

const parseProvidersFilter = (filter) => {
  switch (filter) {
    case 'for-game-creation':
      return { providerGameCreationType: 'manual' };
    default:
      return {};
  }
};

export default {
  namespaced: true,
  state: {
    providers: Object.create(null),
    providersFilter: undefined, // 'for-game-creation' | undefined
    isProvidersLoading: false,
    selectedProviderName: '',
  },
  getters: {
    baseProvidersList(state) {
      return Object.keys(state.providers);
    },
  },
  mutations: {
    setProviders(state, { providersMap, filter }) {
      state.providers = providersMap || Object.create(null);
      state.providersFilter = filter;
    },
    setProvidersLoading(state, isLoading) {
      state.isProvidersLoading = isLoading;
    },
    setSelectedProvider(state, providerName) {
      state.selectedProviderName = providerName || '';
    },
  },
  actions: {
    selectProvider({ commit }, providerName) {
      commit('setSelectedProvider', providerName);
    },

    validateSelectedProvider({ commit, state }) {
      if (
        state.providers[state.selectedProviderName]
        || Object.values(state.providers).some(list => list.includes(state.selectedProviderName))
      ) return;
      commit('setSelectedProvider', '');
    },

    async fetchProviders({ commit, dispatch, state }, filter) {
      if (state.isProvidersLoading === true) return;
      commit('setProvidersLoading', true);
      try {
        const providersMap = await getProvidersWithSubProviders(parseProvidersFilter(filter));
        commit('setProviders', { providersMap, filter });
        dispatch('validateSelectedProvider');
      } catch {
        commit('setProviders', Object.create(null));
      } finally {
        commit('setProvidersLoading', false);
      }
    },
  },
};
