import LoginPage from '@/views/LoginPage.vue';
import HoldersPage from '@/views/HoldersPage/HoldersPage.vue';
import TopHoldersPage from '@/views/HoldersPage/TopHoldersPage.vue';

export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'holders' },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/holders',
    name: 'holders',
    component: HoldersPage,
  },
  {
    path: '/top-holders',
    name: 'top-holders',
    component: TopHoldersPage,
  },
];
