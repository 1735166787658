<template lang="pug">
  .holders-page(v-loading.fullscreen.lock="isLoading")
    ElAlert(
      v-if="fetchingError !== null"
      type="error"
      title="Failed to get holders list"
      :description="fetchingError"
      :closable="false"
      show-icon
      style="margin:1rem 0;"
    )

    TopHoldersTable(
      :holdersList="topHolders"
    )
</template>

<script>
import { getTopHoldersList } from '@/api';
import TopHoldersTable from './components/TopHoldersTable.vue';

const MODAL_STATE = Object.freeze({
  NONE: 'none',
});

export default {
  name: 'TopHoldersPage',
  components: { TopHoldersTable },
  data() {
    return {
      isLoading: false,
      fetchingError: null,
      topHolders: [],
      selectedHolder: null,
      modal: MODAL_STATE.NONE,
      MODAL_STATE,
    };
  },
  methods: {
    async fetchHolders() {
      this.isLoading = true;
      this.fetchingError = null;
      try {
        this.topHolders = (await getTopHoldersList()) || [];
      } catch (error) {
        this.fetchingError = error.toString();
        this.topHolders = [];
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchHolders();
  },
};
</script>
