import { apiClient } from './api-client';

/**
 * @typedef {Object} GetProvidersParams
 * @property {string | undefined} provider
 * @property {'manual' | 'api' | undefined} providerGameCreationType
 */

/**
 * @param {GetProvidersParams} params
 * @returns {Promise<Record<string, string[]>>}
 */
export const getProvidersWithSubProviders = async (
  { provider, providerGameCreationType } = Object.create(null),
) => {
  const { data } = await apiClient.get(
    '/providers',
    { params: { provider, providerGameCreationType } },
  );
  return data;
};
