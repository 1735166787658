<template lang="pug">
  .login-page
    ElForm(:model="form" @submit.prevent.native="submitForm" :rules="rules" ref="form" class="form")
      ElFormItem(label="Name" prop="name")
        ElInput(type="text" autocomplete="on" v-model="form.name")
      ElFormItem(label="Password" prop="pass")
        ElInput(type="password" autocomplete="on" v-model="form.pass")
      ElFormItem
        ElButton(type="primary" native-type="submit") Submit
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        name: '',
        pass: '',
      },
      rules: {
        name: [
          { required: true, message: 'Please input name', trigger: 'blur' },
        ],
        pass: [
          { required: true, message: 'Please input password', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.tryToLogin();
          return true;
        }
        return false;
      });
    },
    async tryToLogin() {
      await this.login(this.$data.form);
      this.$router.replace({ name: 'holders' });
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.form {
  min-width: 350px;
  margin-bottom: 7%;
}
</style>
