import { getAllUsers, deleteUser, createUser } from '@/api';

export default {
  namespaced: true,
  state: {
    usersLoading: false,
    usersList: [],
  },
  mutations: {
    setUsersList(state, list) {
      state.usersList = list;
    },

    setUsersLoading(state, value) {
      state.usersLoading = value;
    },
  },
  actions: {
    async fetchAllUsers({ commit }) {
      commit('setUsersLoading', true);
      const list = await getAllUsers();
      commit('setUsersList', list);
      commit('setUsersLoading', false);
    },

    async deleteUser({ dispatch, commit }, id) {
      commit('setUsersLoading', true);
      await deleteUser(id);
      commit('setUsersLoading', false);
      dispatch('fetchAllUsers');
    },

    async createUser({ dispatch }, data) {
      await createUser(data);
      dispatch('fetchAllUsers');
    },
  },
};
