import { apiClient } from './api-client';

const {
  VUE_APP_API_OFFLINE_MODE,
  VUE_APP_IMAGES_SERVER_HOST,
} = process.env;

const normalizeGame = g => ({
  ...g,
  createdAt: new Date(g.createdAt),
  updatedAt: new Date(g.updatedAt),
  deletedAt: g.deleted ? new Date(g.deletedAt) : null,
  imageUrl: g.imageUrl ? `${VUE_APP_IMAGES_SERVER_HOST}${g.imageUrl}` : null,
});

export const getGames = async (localeId) => {
  if (VUE_APP_API_OFFLINE_MODE === 'true') {
    const g = await import('./samples/games.json');
    return g.default.map(normalizeGame);
  }
  const { data } = await apiClient.get('/games', { params: { localeId } });
  return data.map(normalizeGame);
};

export const uploadImageForGame = async ({
  gameId, file, compressImages, localeId,
}) => {
  const form = new FormData();
  form.append('gameId', gameId);
  form.append('localeId', localeId);
  form.append('image', file);
  form.append('compressImage', compressImages);

  return apiClient.post('/actions/change-image', form);
};
