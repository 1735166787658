import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import action from './modules/action';
import auth from './modules/auth';
// import game from './modules/game';
// import locale from './modules/locale';
import user from './modules/user';
import provider from './modules/provider';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    // action,
    auth,
    // game,
    // locale,
    user,
    provider,
  },
  plugins: [createPersistedState({
    key: 'proxy_key',
    paths: [
      // 'game.pageSize',
      // 'game.searchFilter',
      // 'game.providerFilter',
      // 'game.currentPage',
      // 'game.deletedFilterEnabled',
      // 'game.imagesFilterEnabled',
      // 'game.compressImagesEnable',
      // 'game.tableSorting',
      // 'game.selectedLocale',
      // 'provider.selectedProviderName',
    ],
  })],
});
