<template lang="pug">
  .holders-page(v-loading.fullscreen.lock="isLoading")
    ElRow(type="flex" style="padding:1rem 0.5rem;")
      ElButton(
        type="primary"
        icon="el-icon-plus"
        :loading="isLoading"
        @click="modal = MODAL_STATE.CREATE"
      ) Create holder

    ElAlert(
      v-if="fetchingError !== null"
      type="error"
      title="Failed to get holders list"
      :description="fetchingError"
      :closable="false"
      show-icon
      style="margin:1rem 0;"
    )

    HoldersTable(
      :holdersList="holders"
      @edit="initHolderEditor"
      @delete="onDeleteHolder"
      @toggleActive="toggleHolderActive"
    )

    ElDialog(
      :visible="modal === MODAL_STATE.CREATE"
      v-loading="isLoading"
      title="Create holder"
      fullscreen
      @close="closeHolderForm"
    )
      HolderForm(@submit="onCreateHolder")

    ElDialog(
      v-if="selectedHolder"
      v-loading="isLoading"
      :visible="modal === MODAL_STATE.UPDATE"
      title="Update holder"
      fullscreen
      @close="closeHolderForm"
    )
      HolderForm(:initialData="selectedHolder" @submit="onUpdateHolder")
</template>

<script>
import {
  getHoldersList, createHolder, updateHolder, deleteHolder,
} from '@/api';
import HoldersTable from './components/HoldersTable.vue';
import HolderForm from './components/HolderForm.vue';

const MODAL_STATE = Object.freeze({
  NONE: 'none',
  CREATE: 'create_holder',
  UPDATE: 'update_holder',
});

export default {
  name: 'HoldersPage',
  components: { HoldersTable, HolderForm },
  data() {
    return {
      isLoading: false,
      fetchingError: null,
      holders: [],
      selectedHolder: null,
      modal: MODAL_STATE.NONE,
      MODAL_STATE,
    };
  },
  methods: {
    async fetchHolders() {
      this.isLoading = true;
      this.fetchingError = null;
      try {
        this.holders = (await getHoldersList()) || [];
      } catch (error) {
        this.fetchingError = error.toString();
        this.holders = [];
      } finally {
        this.isLoading = false;
      }
    },
    async createHolder({
      address, name,
    }) {
      this.isLoading = true;
      try {
        const id = await createHolder({
          address, name,
        });
        this.holders = this.holders.concat({
          id, address, name,
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async updateHolder(
      holderId,
      {
        address, name,
      },
    ) {
      this.isLoading = true;
      try {
        await updateHolder(holderId, {
          address, name,
        });
        await this.fetchHolders();
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async toggleHolderActive({
      id, address, name, isActive,
    }) {
      this.isLoading = true;
      const toggled = !isActive;
      try {
        const result = await updateHolder(id, {
          address, name, isActive: toggled,
        });
        if (result === true) {
          const holderIndex = this.holders.findIndex(p => p.id === id);
          this.holders = [
            ...this.holders.slice(0, holderIndex),
            { ...this.holders[holderIndex], isActive: +toggled },
            ...this.holders.slice(holderIndex + 1),
          ];
        } else {
          await this.fetchHolders();
        }
      } catch {
        this.fetchHolders();
      } finally {
        this.isLoading = false;
      }
    },
    async deleteHolder(id) {
      this.isLoading = true;
      try {
        await deleteHolder(id);
        const holderIndex = this.holders.findIndex(p => p.id === id);
        this.holders = [
          ...this.holders.slice(0, holderIndex),
          ...this.holders.slice(holderIndex + 1),
        ];
      } catch (error) {
        this.fetchHolders();
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    initHolderEditor(holder) {
      this.selectedHolder = { ...holder };
      this.modal = MODAL_STATE.UPDATE;
    },
    closeHolderForm() {
      this.selectedHolder = null;
      this.modal = MODAL_STATE.NONE;
    },
    async onCreateHolder(holder) {
      try {
        await this.createHolder(holder);
        this.closeHolderForm();
        this.$message({
          type: 'success',
          message: `Holder "${holder.name || holder.address}" created`,
        });
      } catch {
        // noop
      }
    },
    async onUpdateHolder(holderDto) {
      if (!this.selectedHolder) return;
      try {
        await this.updateHolder(this.selectedHolder.id, holderDto);
        this.closeHolderForm();
        this.$message({
          type: 'success',
          message: 'Holder updated',
        });
      } catch {
        // noop
      }
    },
    async onDeleteHolder(holder) {
      try {
        await this.$confirm(`This will permanently delete the holder "${holder.name || holder.address}". Continue?`, 'Delete holder');
        await this.deleteHolder(holder.id);
        if (this.selectedHolder !== null && this.selectedHolder.id === holder.id) {
          this.selectedHolder = null;
        }
        this.$message({
          type: 'success',
          message: 'Delete completed',
        });
      } catch {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      }
    },
  },
  mounted() {
    this.fetchHolders();
  },
};
</script>
