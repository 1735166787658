import { apiClient } from './api-client';

export const getAllUsers = async () => {
  const { data } = await apiClient.get('/users');
  return data;
};

export const deleteUser = async (id) => {
  await apiClient.delete(`/users/${id}`);
};

export const createUser = async ({
  name, password, role, providerName,
}) => {
  const form = new FormData();
  form.append('name', name);
  form.append('password', password);
  form.append('role', role);

  if (providerName && providerName.length > 0) {
    form.append('providerName', providerName);
  }

  await apiClient.post('/users', form);
};
