<template lang="pug">
  .aside-menu
    ElMenu.menu(
      router
      mode="horizontal"
      :default-active="$route.path"
    )
      .menu-container
        .left-menu
          ElMenuItem(index="/holders") Holders
        .left-menu
          ElMenuItem(index="/top-holders") Top holders
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['userData']),
    ...mapState('locale', [
      'selectedLocale',
      'locales',
    ]),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('action', ['unprocessedActions']),
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('locale', ['selectLocale']),
  },
};
</script>
<style>
.aside-menu .el-badge__content {
  transform: translateY(20%) translateX(100%) !important;
}
.aside-menu .el-submenu__icon-arrow {
  right: 5px;
  margin-top: -5px;
}
.aside-menu .is-opened .el-submenu__icon-arrow {
  margin-top: -7px;
}
</style>
<style scoped>
.aside-menu {
  width: 100%;
}
.menu {
  padding: 0 25px;
}

.menu-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.left-menu, .right-menu {
  display: flex;
}

.locale-select {
  width: 150px;
  margin-right: 15px;
}

.username {
  margin-right: 6px;
  margin-left: 2px;
  color: #909399;
}

.locale-flag {
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.select-prefix {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 2px;
}

.select-text {
  font-size: 14px;
  font-weight: normal;
  color: #606266;
}
</style>
