import { apiClient } from './api-client';

export const getHoldersList = async () => {
  const { data } = await apiClient.get('/holders');
  return data.map((h) => ({
    ...h,
    amount: `${h.sale_amount_wwy} WWY ($${h.sale_amount_usd})`,
    balanceWWY: `${h.balance_wwy} WWY`,
    balanceUSD: `$${h.balance_usd}`,
  }));
};

export const createHolder = async ({
  address, name,
}) => {
  const { data } = await apiClient.post(
    '/holders',
    {
      address, name,
    },
    { withCredentials: true },
  );
  return data;
};

export const updateHolder = async (
  holderId,
  {
    address, name,
  },
) => {
  const { data } = await apiClient.put(
    `/holders/${holderId}`,
    {
      address, name,
    },
    { withCredentials: true },
  );
  return data;
};

export const deleteHolder = async (holderId) => {
  const { data } = await apiClient.delete(`/holders/${holderId}`, { withCredentials: true });
  return data;
};

export const getTopHoldersList = async () => {
  const { data } = await apiClient.get('/top-holders', { withCredentials: true });
  return data.map((h) => ({
    ...h,
    quantity: h.quantity.split('.')[0],
    percentage: `${parseFloat(h.percentage).toFixed(2)}%`,
    amount: h.amount.split('.')[0],
  }));
};

