<template lang="pug">
  #app
    TopMenu(v-if="this.$route.name !== 'login'")
    router-view
</template>

<script>
import { mapActions } from 'vuex';
import TopMenu from '@/components/TopMenu.vue';
import { setApiGlobalErrorHandler } from '@/api';

export default {
  components: {
    TopMenu,
  },
  async mounted() {
    setApiGlobalErrorHandler((err) => {
      if (err.response.status === 401) {
        return;
      }
      if (err.response.data) {
        this.$message({
          message: err.response.data,
          type: 'error',
        });
      } else {
        this.$message({
          message: 'Unknown error',
          type: 'error',
        });
      }
    });

    const isLoginPage = this.$route.name === 'login';

    if (process.env.VUE_APP_API_OFFLINE_MODE === 'true') {
      return;
    }

    try {
      await this.getUserData();
      if (isLoginPage) {
        this.$router.replace({ name: 'holders' });
      }
    } catch (e) {
      if (!isLoginPage) {
        this.$router.replace({ name: 'login' });
      }
    }
  },

  methods: {
    ...mapActions('auth', ['getUserData']),
  },
};
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.cursor-pointer { cursor: pointer; }
.loading {
  cursor: wait;
}
.w-full { width: 100%; }
.h-full { height: 100%; }
.min-w-1\/2 { min-width: 50%; }
.min-w-1\/4 { min-width: 25%; }
.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.ml-1 {
  margin-left: .25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.text-left {
  text-align: left;
}
.overflow-auto { overflow: auto; }
</style>
