<template lang="pug">
  ElForm.holder-form(
    v-loading="isCreatingDataLoading"
    ref="holderForm"
    label-position="top"
    :model="holder"
    :rules="rules"
    @submit.native.prevent="submit"
  )
    ElFormItem(label="Address" prop="address")
      ElInput(v-model="holder.address")
    ElFormItem(label="Name" prop="name")
      ElInput(v-model="holder.name")
    ElFormItem
      ElButton(type="primary" native-type="submit") Submit
</template>

<script>

const getEmptyHolder = () => ({
  address: '',
  name: '',
  // startsAt: new Date(),
});

const parseHolderToFormData = ({
  address, name,
}) => ({
  address, name,
});

export default {
  name: 'HolderForm',
  props: {
    initialData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      holder: this.initialData
        ? parseHolderToFormData(this.initialData)
        : getEmptyHolder(),
      isCreatingDataLoading: false,
    };
  },
  computed: {
    rules() {
      return {
        address: [{ required: true, message: 'Please input holder address' }],
        name: [{ required: false }],
        // startsAt: [{ type: 'date', required: true }],
        // endsAt: [{ type: 'date', required: true }],
      };
    },
  },
  methods: {
    async fetchCreatingData() {
      this.isCreatingDataLoading = true;
      this.isCreatingDataLoading = false;
    },
    submit() {
      this.$refs.holderForm.validate((valid, errors) => {
        if (!valid) {
          this.$message.error(`Check fields: ${Object.keys(errors).join(', ')}.`);
          return;
        }
        this.$emit('submit', this.holder);
      });
    },
    resetFields() {
      this.$refs.holderForm.resetFields();
    },
  },
  mounted() {
    this.fetchCreatingData();
  },
};
</script>

<style>
.holder-form {
  text-align: left;
}
.holder-form .el-input.el-date-editor {
  width: 100%;
}
</style>
