import { apiClient } from './api-client';

export const getUserData = async () => {
  const { data } = await apiClient.get('/user-info');
  return data;
};

export const login = async ({ name, pass }) => {
  const form = new FormData();
  form.append('name', name);
  form.append('password', pass);

  const { data } = await apiClient.post('/login', {
    name, password: pass,
  });
  return data;
};

export const logout = async () => {
  await apiClient.post('/logout');
};
